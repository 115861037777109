"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  function r(e, n, t) {
    function o(i, f) {
      if (!n[i]) {
        if (!e[i]) {
          var c = "function" == typeof require && require;if (!f && c) return c(i, !0);if (u) return u(i, !0);var a = new Error("Cannot find module '" + i + "'");throw a.code = "MODULE_NOT_FOUND", a;
        }var p = n[i] = { exports: {} };e[i][0].call(p.exports, function (r) {
          var n = e[i][1][r];return o(n || r);
        }, p, p.exports, r, e, n, t);
      }return n[i].exports;
    }for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) {
      o(t[i]);
    }return o;
  }return r;
})()({ 1: [function (require, module, exports) {
    module.exports = function (name) {
      var SEARCH = window.location.search;
      var reg = new RegExp("(\\?|&)" + name + "(\\[\\])?=([^&]*)");
      var value = SEARCH.match(reg);

      return value ? value[3] : '';
    };
  }, {}], 2: [function (require, module, exports) {
    var param = require('bmh/param');
    var app = param('from');

    var TugouBrowser = function () {
      function TugouBrowser() {
        _classCallCheck(this, TugouBrowser);

        this.ua = navigator.userAgent;
      }
      // 获取是什么环境  app/m/pc/wx


      _createClass(TugouBrowser, [{
        key: "getEnvironment",
        value: function getEnvironment() {
          var _this = this;

          var environment = void 0;

          var agent_list = ["Android", "iPhone", "Windows Phone", "iPad", "iPod"];
          var judge_m_arr = [];
          // m
          judge_m_arr = agent_list.filter(function (item) {
            return _this.ua.indexOf(item) != -1;
          });
          if (judge_m_arr.length > 0) {
            environment = 'm';
          }
          // wx
          if (this.ua.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
            environment = 'wx';
          }
          // app
          if (app === 'app') {
            environment = 'app';
          }
          if (!environment) {
            environment = 'pc';
          }
          return environment;
        }
        // 获取浏览器名字  因为很多浏览器用的是chrome的内核，所以判断的时候要放在chrome的前面，不然就被判定为chrome了，chrome要放在Safari前面

      }, {
        key: "getBrowserName",
        value: function getBrowserName() {
          var name = void 0;
          if (this.ua.indexOf("OPR") != -1) {
            name = 'Opera';
          } else if (this.ua.indexOf("MSIE") != -1) {
            name = 'MSIE';
          } else if (this.ua.indexOf("UBrowser") != -1) {
            name = 'UCBrowser';
          } else if (this.ua.indexOf("BIDUBrowser") != -1) {
            name = 'BIDUBrowser';
          } else if (this.ua.indexOf("QQBrowser") != -1) {
            name = 'QQBrowser';
          } else if (this.ua.indexOf("Chrome") != -1) {
            name = 'Chrome';
          } else if (this.ua.indexOf("Safari") != -1) {
            name = 'Safari';
          } else if (this.ua.indexOf("Firefox") != -1) {
            name = 'Firefox';
          }
          return name;
        }
      }]);

      return TugouBrowser;
    }();

    module.exports = TugouBrowser;
  }, { "bmh/param": 1 }], 3: [function (require, module, exports) {
    'use strict';

    /**
     * 初始化jsbridge
     * @param readyCallback 初始化完成后的回调
     */

    var TugouBrowser = require('bmh/tugou-browser');
    var brwoser_environment = new TugouBrowser().getEnvironment(); // 浏览器环境


    window.app_bridge = {};

    function initJsBridge(readyCallback) {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端

      // 注册jsbridge
      function connectWebViewJavascriptBridge(callback) {
        if (isAndroid) {
          if (window.WebViewJavascriptBridge) {
            callback(WebViewJavascriptBridge);
          } else {
            document.addEventListener('WebViewJavascriptBridgeReady', function () {
              callback(WebViewJavascriptBridge);
            }, false);
          }
          return;
        }

        if (isiOS) {
          if (window.WebViewJavascriptBridge) {
            return callback(WebViewJavascriptBridge);
          }
          if (window.WVJBCallbacks) {
            return window.WVJBCallbacks.push(callback);
          }
          window.WVJBCallbacks = [callback];
          var WVJBIframe = document.createElement('iframe');
          WVJBIframe.style.display = 'none';
          WVJBIframe.src = 'https://__bridge_loaded__';
          document.documentElement.appendChild(WVJBIframe);
          setTimeout(function () {
            document.documentElement.removeChild(WVJBIframe);
          }, 0);
        }
      }

      // 调用注册方法
      connectWebViewJavascriptBridge(function (bridge) {
        if (isAndroid) {
          bridge.init(function (message, responseCallback) {
            console.log('JS got a message', message);
            responseCallback(data);
          });
        }
        readyCallback();
      });
    }

    app_bridge.init = function () {
      if (brwoser_environment !== 'app') {
        return false;
      }
      initJsBridge(function () {
        // 执行到这里就已经初始化好了jsbridge
        app_bridge.ready && app_bridge.ready();
      });
    };

    /**
     * 判断是否登录
     * 返回参数 Promise对象
     * is_login: boolean类型类型，是否登录，
     */
    app_bridge.isLogin = function () {
      return new Promise(function (resolve) {
        window.WebViewJavascriptBridge.callHandler('isLogin', null, function (response) {
          response = JSON.parse(response);
          var data = response.data;
          resolve({ is_login: data.isLogin });
        });
      });
    };

    /**
     * 获取登录用户的身份信息
     * 返回参数 Promise对象
     * user_id: String类型，当前登录用户的ID
     * skey: String类型，当前登录用户的Skey
     * mobile: String类型，当前登录用户的手机号
     */
    app_bridge.getLoginUser = function () {
      return new Promise(function (resolve) {
        window.WebViewJavascriptBridge.callHandler('getLoginUser', null, function (response) {
          response = JSON.parse(response);
          var data = response.data;
          resolve({ user_id: data.user_id, skey: data.skey, mobile: data.mobile });
        });
      });
    };

    /**
     * 获取APP基本信息
     * 返回参数 Promise对象
     * app_version: String类型，APP版本号，如：3.12.1
     * app_name: String类型，APP的名字，可选值：shang
     * app_os: String类型，APP的操作系统，可选值：android | ios
     */
    app_bridge.getAppInfo = function () {
      return new Promise(function (resolve) {
        window.WebViewJavascriptBridge.callHandler('getAppInfo', null, function (response) {
          response = JSON.parse(response);
          var data = response.data;
          resolve({ app_version: data.app_version, app_name: data.app_name, app_os: data.app_os });
        });
      });
    };

    // 发起APP登录
    app_bridge.login = function (callback) {
      var source = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      return new Promise(function (resolve) {
        window.WebViewJavascriptBridge.callHandler('login', source, function (response) {
          response = JSON.parse(response);
          var data = response.data;
          if (response.error === 0) {
            var user_id = data.user_id;
            var skey = data.skey;
            var mobile = data.mobile;
            !!callback ? callback({ user_id: user_id, skey: skey, mobile: mobile }) : function () {};
            resolve({ user_id: data.user_id, skey: data.skey, mobile: data.mobile });
          } else {
            resolve({ message: data.message });
          }
        });
      });
    };

    /**
     * JS调用APP的页面跳转
     * @param {*要跳转的页面地址} url
     */
    app_bridge.location = function (url) {
      window.WebViewJavascriptBridge.callHandler('location', { 'url': url }, null);
    };

    /**
     * JS调用APP的分享组件
     * share 分享数据
     */
    app_bridge.appShare = function (share, callback) {
      return new Promise(function (resolve) {
        window.WebViewJavascriptBridge.callHandler('appShare', share, function (response) {
          response = JSON.parse(response);
          !!callback ? callback({ error: response.error }) : function () {};
          resolve({ error: response.error });
        });
      });
    };

    /**
     * 展示APP分享按钮，并回调监听分享按钮点击 最小版本：4.4.1
     *每次点击APP原生按钮发起分享之后需要重新注入showShareBtn
     * share 分享数据
    */
    app_bridge.showShareBtn = function (share, callback) {
      window.WebViewJavascriptBridge.callHandler('showShareBtn', null, function (response) {
        app_bridge.appShare(share, callback);
        !!callback ? callback({ response: response }) : function () {};
        app_bridge.showShareBtn(share, callback);
      });
    };

    /**
     * JS调用APP的复制到粘贴板方法
     * share 分享数据
    */
    app_bridge.copyToClipboard = function (content) {
      window.WebViewJavascriptBridge.callHandler('copyToClipboard', { 'content': content }, null);
    };

    /**
     * 获取当前选中的城市
     * 返回参数 Promise对象
     * branch: String类型，当前选中的城市
     * longitude: String类型，当前位置的经度
     * latitude: String类型，当前位置的维度
    */
    app_bridge.getBranch = function () {
      return new Promise(function (resolve) {
        window.WebViewJavascriptBridge.callHandler('getBranch', null, function (response) {
          response = JSON.parse(response);
          var data = response.data;
          resolve({ branch: data.branch, longitude: data.longitude, latitude: data.latitude });
        });
      });
    };

    /**
     * 打开APP的客服咨询
     * @param {*来源内容} source
     * @param {*用于标记是哪个页面调起的客服咨询,方便客服同学查看来源} source.title
     * @param {*表示该页面的url,选传} source.url
    */
    app_bridge.openCustomService = function (source) {
      window.WebViewJavascriptBridge.callHandler('openCustomService', { sourceTitle: source.title, sourceUrl: source.url }, null);
    };

    /**
     * 打开打开APP地图页 最小版本：4.3.2
     * @param {*地址信息} location
     * @param {*经度} location.latitude
     * @param {*纬度} location.longitude
     * @param {*地址名} location.location_name
    */
    app_bridge.openMap = function (location) {
      window.WebViewJavascriptBridge.callHandler('openMap', { latitude: location.latitude, longitude: location.longitude, locationName: location.location_name }, null);
    };

    /**
     * 调用支付宝支付 最小版本：4.4.1
     * @param {*支付信息} pay_info
     * @param {*payString} pay_info.payString
     *
     * 返回参数 Promise对象
     * success: Boolean类型，支付是否成功 true 是 false 否
    */
    app_bridge.alipay = function (pay_info, callback) {
      return new Promise(function (resolve) {
        window.WebViewJavascriptBridge.callHandler('alipay', pay_info, function (response) {
          response = JSON.parse(response);
          !!callback ? callback(response) : function () {};
          resolve(response);
        });
      });
    };

    /**
     * 调用微信支付 最小版本：4.4.1
     * @param {*支付信息} pay_info
     * @param {*appid} pay_info.appid
     * @param {*partnerid} pay_info.partnerid
     * @param {*prepayid} pay_info.prepayid
     * @param {*package} pay_info.package
     * @param {*noncestr} pay_info.noncestr
     * @param {*timestamp} pay_info.timestamp
     * @param {*sign} pay_info.sign
     *
     * 返回参数 Promise对象
     * success: Boolean类型，支付是否成功 true 是 false 否
    */
    app_bridge.wxpay = function (pay_info, callback) {
      return new Promise(function (resolve) {
        window.WebViewJavascriptBridge.callHandler('wxpay', pay_info, function (response) {
          response = JSON.parse(response);
          !!callback ? callback(response) : function () {};
          resolve(response);
        });
      });
    };
    /**
     * JS调用APP的跳转淘宝或天猫
     * @param {*要跳转的页面地址} url
     */
    app_bridge.openAliMall = function (url) {
      window.WebViewJavascriptBridge.callHandler('openAliMall', { "pageUrl": url, "linkKey": "taobao_scheme" }, null);
    };

    /**
     * app环境下装修风格测试内嵌页判断是否完成装修测试(此方法为适应性方法可能在app后续版本中修改 只适用于临时使用)
     * 最小版本：4.9.2
     */
    app_bridge.completeDecorTest = function () {
      window.WebViewJavascriptBridge.callHandler('completeDecorTest', { "popup_id": 1 }, null);
    };

    /**
     * 调用app原生toast方法
     * 参数:context：totast内容
     * 最小版本：4.9.2
     */
    app_bridge.toast = function (context) {
      window.WebViewJavascriptBridge.callHandler('toast', { "text": context }, null);
    };
  }, { "bmh/tugou-browser": 2 }] }, {}, [3]);